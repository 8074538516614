<template>
  <div>
    <v-row>
      <v-col lg="3" md="6" cols="12">
        <v-card flat color="grey lighten-5">

          <v-card-title class="card-title">
            <div class="d-flex align-center justify-space-between">

              <span class="display-1">{{ _totalImpressions }}</span>

              <div class="d-flex flex-column align-end">

                <v-chip x-small :color="metricPercentageChange('total_impressions')?.color" label
                  :text-color="metricPercentageChange('total_impressions')?.textColor" class="font-weight-bold">
                  <v-icon x-small left>
                    {{ metricPercentageChange('total_impressions')?.icon }}
                  </v-icon>
                  {{ metricPercentageChange('total_impressions')?.value }} %
                </v-chip>
                <span class="grey--text text-caption">Last 24 hours</span>
              </div>
            </div>
          </v-card-title>

          <v-card-subtitle class="overline pb-0">
            Total Impressions
          </v-card-subtitle>

          <div id="container">
            <mini-cards-graph :data="_impressionsBreakdown" color="#80CBC4" />
          </div>

        </v-card>
      </v-col>

      <v-col lg="3" md="6" cols="12">
        <v-card flat color="grey lighten-5">

          <v-card-title class="card-title">

            <div class="d-flex align-center justify-space-between">

              <span class="display-1">{{ _totalSpend }}</span>

              <div class="d-flex flex-column align-end">

                <v-chip x-small :color="metricPercentageChange('total_spend')?.color" label
                  :text-color="metricPercentageChange('total_spend')?.textColor" class="font-weight-bold">
                  <v-icon x-small left>
                    {{ metricPercentageChange('total_spend')?.icon }}
                  </v-icon>
                  {{ metricPercentageChange('total_spend')?.value }} %
                </v-chip>

                <span class="grey--text text-caption">Last 24 hours</span>

              </div>
            </div>
          </v-card-title>

          <v-card-subtitle class="overline pb-0"> Total Spend </v-card-subtitle>
          <div id="container">
            <MiniCardsGraph :data="_spendBreakdown" color="#ffb01a" />
          </div>
        </v-card>
      </v-col>

      <v-col lg="3" md="6" cols="12">
        <v-card flat color="grey lighten-5">
          <v-card-title class="card-title">

            <div class="d-flex align-center justify-space-between">

              <span class="display-1">{{ _totalClicks }}</span>

              <div class="d-flex flex-column align-end">

                <v-chip x-small :color="metricPercentageChange('total_clicks')?.color" label
                  :text-color="metricPercentageChange('total_clicks')?.textColor" class="font-weight-bold">
                  <v-icon x-small left>
                    {{ metricPercentageChange('total_clicks')?.icon }}
                  </v-icon>
                  {{ metricPercentageChange('total_clicks')?.value }} %
                </v-chip>

                <span class="grey--text text-caption">Last 24 hours</span>

              </div>
            </div>
          </v-card-title>
          <v-card-subtitle class="overline pb-0"> Total Clicks </v-card-subtitle>

          <div id="container">
            <MiniCardsGraph :data="_clicksBreakdown" color="#008ffb" />
          </div>
        </v-card>
      </v-col>

      <v-col lg="3" md="6" cols="12">
        <v-card flat color="grey lighten-5">
          <v-card-title class="card-title">
            <div class="d-flex align-center justify-space-between">

              <span class="display-1">{{ _ctr }}%</span>

              <div class="d-flex flex-column align-end">

                <v-chip x-small :color="calculatePercentageChangeForCTR()?.color" label
                  :text-color="calculatePercentageChangeForCTR()?.textColor" class="font-weight-bold">
                  <v-icon x-small left>
                    {{ calculatePercentageChangeForCTR()?.icon }}
                  </v-icon>
                  {{ calculatePercentageChangeForCTR()?.value }} %
                </v-chip>

                <span class="grey--text text-caption">Last 24 hours</span>

              </div>
            </div>
          </v-card-title>
          <v-card-subtitle class="overline pb-0"> Total CTR </v-card-subtitle>

          <div id="container">
            <MiniCardsGraph :data="_ctrBreakdown" color="#50e7a6" />
          </div>
        </v-card>

      </v-col>
    </v-row>

  </div>
</template>

<script>
// import { computed } from 'vue';
import {
  compactNumber,
  usCurrencyFormatterCompact,
} from "@/utils/numberFormatter.js";

import MiniCardsGraph from "./MiniCardsGraph.vue";

export default {
  components: {
    MiniCardsGraph,
  },
  data() {
    return {
      totalImpressions: 0,
      totalSpend: 0,
      totalCTR: 0,
      totalClicks: 0,
    };
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  computed: {
    all_campaigns_reports() {
      return this.$store.state.dsp.all_campaigns_reports;
    },

    _totalImpressions() {
      const impressions = this.data.map((item) => {
        return item.total_impressions;
      });
      return compactNumber(this.sumArray(impressions));
    },

    _totalSpend() {
      const spend = this.data.map((item) => {
        return Number(item.total_spend);
      });
      const totalSpend = this.sumArray(spend);
      // return usCurrencyFormatterCompact((totalSpend * 100) / 30); //added hack to return higher spend

      return usCurrencyFormatterCompact(totalSpend);
    },
    _totalClicks() {
      const clicks = this.data.map((item) => {
        return item.total_clicks;
      });
      return compactNumber(this.sumArray(clicks));
    },

    _ctr() {
      // calculate total ctr
      const impressions = this.data.map((item) => {
        return item.total_impressions;
      });

      const totalImpressions = this.sumArray(impressions);

      const clicks = this.data.map((item) => {
        return item.total_clicks;
      });
      const totalClicks = this.sumArray(clicks);

      if (totalClicks === 0 && totalImpressions === 0) {
        return 0;
      } else {
        return ((totalClicks / totalImpressions) * 100).toFixed(2);
      }
    },

    _impressionsBreakdown() {
      return this.extractValuesByKey(this.data, 'total_impressions');

      // let impressionsTrend =  this.downsampleData(data, 20);

      // return impressionsTrend;
    },

    _spendBreakdown() {
      let data = this.extractValuesByKey(this.data, 'total_spend');

      data = data.filter(item => item !== '0')

      //convert to float
      data = data.map(item => parseFloat(item))

      let spendTrend = this.downsampleData(data, 20)

      return spendTrend
    },

    _clicksBreakdown() {
      let data = this.extractValuesByKey(this.data, 'total_clicks');

      let clicksTrend = this.downsampleData(data, 50)

      return clicksTrend
    },

    _ctrBreakdown() {
      let data = this.extractValuesByKey(this.data, 'average_ctr')

      //remove 0 values
      data = data.filter(item => item !== '0')

      //convert to float
      data = data.map(item => parseFloat(item))

      let ctrTrend = this.downsampleData(data, 50)

      return ctrTrend
    },

  },

  methods: {
    sumArray(array) {
      return array.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
    },
    extractValuesByKey(data, key) {
      return data.map(item => item[key]);
    },
    downsampleData(data, numberOfPoints) {
      const interval = Math.ceil(data.length / numberOfPoints);
      const downsampledData = [];

      for (let i = 0; i < data.length; i += interval) {
        const chunk = data.slice(i, i + interval);
        const average = chunk.reduce((acc, value) => acc + value, 0) / chunk.length;
        downsampledData.push(average);
      }

      return downsampledData;
    },

    metricPercentageChange(metric) {

      const metricToCalculate = metric;

      let dates = this.getYesterdayAndDayBeforeYesterdayDates();

      const yesterdayMetric = this.calculateMetricForDate(metricToCalculate, dates.yesterday);
      const dayBeforeYesterdayMetric = this.calculateMetricForDate(metricToCalculate, dates.dayBeforeYesterday);

      const percentageChange = this.calculatePercentageChange(yesterdayMetric, dayBeforeYesterdayMetric);

      return this.statsColorAndSign(percentageChange);
    },

    calculateMetricForDate(metric, dateString) {
      const targetDate = new Date(dateString);
      let totalMetric = 0;
      for (const entry of this.data) {
        const entryDate = new Date(entry.date);
        if (entryDate.getDate() === targetDate.getDate() &&
          entryDate.getMonth() === targetDate.getMonth() &&
          entryDate.getFullYear() === targetDate.getFullYear()) {
          totalMetric += parseInt(entry[metric], 10);
        }
      }
      return totalMetric;
    },

    calculatePercentageChangeForCTR() {
      let dates = this.getYesterdayAndDayBeforeYesterdayDates();

      //impressions for yesterday
      const yesterdayImpressions = this.calculateMetricForDate('total_impressions', dates.yesterday);
      const dayBeforeYesterdayImpressions = this.calculateMetricForDate('total_impressions', dates.dayBeforeYesterday);

      const yesterdaySpend = this.calculateMetricForDate('total_spend', dates.yesterday);
      const dayBeforeYesterdaySpend = this.calculateMetricForDate('total_spend', dates.dayBeforeYesterday);

      const yesterdayCTR = (yesterdaySpend / yesterdayImpressions) * 100;
      const dayBeforeYesterdayCTR = (dayBeforeYesterdaySpend / dayBeforeYesterdayImpressions) * 100;

      const percentageChange = this.calculatePercentageChange(yesterdayCTR, dayBeforeYesterdayCTR);

      return this.statsColorAndSign(percentageChange);
    },

    getYesterdayAndDayBeforeYesterdayDates() {
      // Get today's date
      const today = new Date();

      // Get yesterday's date
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      // Get the day before yesterday's date
      const dayBeforeYesterday = new Date(yesterday);
      dayBeforeYesterday.setDate(yesterday.getDate() - 1);

      // Convert dates to string in the format "YYYY-MM-DD"
      const yesterdayDate = yesterday.toISOString().split('T')[0];
      const dayBeforeYesterdayDate = dayBeforeYesterday.toISOString().split('T')[0];

      return {
        yesterday: yesterdayDate,
        dayBeforeYesterday: dayBeforeYesterdayDate
      };
    },

    calculatePercentageChange(todayValue, yesterdayValue) {

      todayValue = parseFloat(todayValue);
      yesterdayValue = parseFloat(yesterdayValue);

      if (!isNaN(todayValue) && !isNaN(yesterdayValue)) {
        if (yesterdayValue === 0) {
          if (todayValue === 0) {
            return 0; // both values are zero, no change
          } else {
            return 100; // positive percentage change when yesterdayValue is zero
          }
        } else {
          // Calculate percentage change
          let change = ((todayValue - yesterdayValue) / Math.abs(yesterdayValue)) * 100;

          // Round the value to two decimal places
          change = Math.round(change * 100) / 100;

          return change;
        }
      } else {
        return 0; // invalid input, return 0
      }
    },

    statsColorAndSign(value) {
      if (value > 0) {
        return {
          color: 'teal lighten-5',
          textColor: 'teal',
          sign: '+',
          value: value,
          icon: 'mdi-trending-up'
        };
      } else if (value < 0) {
        return {
          color: 'teal lighten-5',
          textColor: 'teal',
          sign: '',
          value: value,
          icon: 'mdi-trending-down'
        };
      } else {
        return {
          color: 'teal lighten-5',
          textColor: 'teal',
          sign: '',
          value: value,
          icon: 'mdi-trending-neutral'
        };
      }
    }

  },
};
</script>

<style scoped>
#container {
  width: 100%;
  display: inline-block;
  overflow: hidden;
}

.card-title {
  flex-wrap: nowrap !important;
  display: block !important;
}
</style>
