<template>
  <div>
    <v-list nav>
      <v-list-item-group v-if="!_isSafaricom && !_isKWSAgency" color="primary">
        <v-list-item to="/" link class="mb-0">
          <v-list-item-icon>
            <v-icon>mdi-home-variant-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-group prepend-icon="mdi-monitor-dashboard" no-action>
          <template v-slot:activator>
            <v-list-item-title>Programmatic</v-list-item-title>
          </template>
          <v-list-item to="/tradedesk/discovery/" link>
            <v-list-item-title>Discovery</v-list-item-title>
          </v-list-item>
          <v-list-item to="/tradedesk/campaigns/" link>
            <v-list-item-title>Campaigns</v-list-item-title>
          </v-list-item>
          <v-list-item to="/tradedesk/creatives/" link>
            <v-list-item-title>Creatives</v-list-item-title>
          </v-list-item>
          <v-list-group no-action sub-group v-if="superAdmin">
            <template v-slot:activator>
              <v-list-item-content class="py-0">
                <v-list-item-title>Audiences</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="([title, url], i) in audiences"
              :key="i"
              :to="url"
              link
            >
              <v-list-item-title>{{ title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-item to="/tradedesk/targeting/" link v-if="!superAdmin">
            <v-list-item-title>Audiences</v-list-item-title>
          </v-list-item>
          <v-list-item to="/tradedesk/reporting/" link>
            <v-list-item-title>Reporting</v-list-item-title>
          </v-list-item>
          <v-list-item to="/tradedesk/leadgen/" link>
            <v-list-item-title>Leads</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-facebook" no-action>
          <template v-slot:activator>
            <v-list-item-title>Facebook</v-list-item-title>
          </template>
          <v-list-item
            v-for="link in facebook"
            :key="link.title"
            :to="link.url"
            link
          >
            <v-list-item-title> {{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-opera" no-action>
          <template v-slot:activator>
            <v-list-item-title>Opera</v-list-item-title>
          </template>
          <v-list-item
            v-for="link in opera"
            :key="link.title"
            :to="link.url"
            link
          >
            <v-list-item-title> {{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group v-if="_isBelva" prepend-icon="mdi-google" no-action>
          <template v-slot:activator>
            <v-list-item-title>Google</v-list-item-title>
          </template>
          <v-list-item
            v-for="link in google"
            :key="link.title"
            :to="link.url"
            link
          >
            <v-list-item-title> {{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group v-else prepend-icon="mdi-parking" no-action>
          <template v-slot:activator>
            <v-list-item-title>Phoenix</v-list-item-title>
          </template>
          <v-list-item
            v-for="link in phoenix"
            :key="link.title"
            :to="link.url"
            link
          >
            <v-list-item-title> {{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-gamepad-variant-outline" no-action>
          <template v-slot:activator>
            <v-list-item-title>Gaming</v-list-item-title>
          </template>
          <v-list-item
            v-for="link in gaming"
            :key="link.title"
            :to="link.url"
            link
          >
            <v-list-item-title> {{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-alpha-t-circle-outline" no-action>
          <template v-slot:activator>
            <v-list-item-title>Transsion</v-list-item-title>
          </template>
          <v-list-item
            v-for="link in transsion"
            :key="link.title"
            :to="link.url"
            link
          >
            <v-list-item-title> {{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-phone" no-action>
          <template v-slot:activator>
            <v-list-item-title>TrueCaller</v-list-item-title>
          </template>
          <v-list-item
            v-for="link in truecaller"
            :key="link.title"
            :to="link.url"
            link
          >
            <v-list-item-title> {{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="mdi-television" no-action>
          <template v-slot:activator>
            <v-list-item-title>Connected TV</v-list-item-title>
          </template>
          <v-list-item
            v-for="link in connected_tv"
            :key="link.title"
            :to="link.url"
            link
          >
            <v-list-item-title> {{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item v-if="superAdmin" to="/direct-deals/deals" class="mb-0">
          <v-list-item-icon>
            <v-icon>mdi-sign-direction</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Direct Deals</v-list-item-title>
        </v-list-item>
        <v-list-group
          v-if="this.superAdmin"
          prepend-icon="mdi-account-multiple-outline"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>Users</v-list-item-title>
          </template>
          <v-list-item
            v-for="link in users"
            :key="link.title"
            :to="link.url"
            link
          >
            <v-list-item-title> {{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-if="!this.superAdmin"
          to="/users-management/clients"
          link
          class="mb-0"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Advertisers </v-list-item-title>
        </v-list-item>
        <v-list-item to="/settings" link class="mb-0">
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Settings </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="!this.superAdmin"
          to="/users-management/users"
          link
          class="mb-0"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-title>Team </v-list-item-title>
        </v-list-item>
        <!-- <v-list-item v-if="this.superAdmin" to="/billing" link class="mb-0">
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Billing </v-list-item-title>
        </v-list-item> -->
        <v-list-group
          v-if="this.superAdmin"
          prepend-icon="mdi-credit-card"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title>Billing</v-list-item-title>
          </template>

          <v-list-item to="/invoicing" link>
            <v-list-item-title>Documents</v-list-item-title>
          </v-list-item>

          <v-list-item to="/payment-methods" link>
            <v-list-item-title>Payment Methods</v-list-item-title>
          </v-list-item>

          <v-list-item to="/payment-history" link>
            <v-list-item-title>Payment History</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item to="/customers" link>
            <v-list-item-title>Customers</v-list-item-title>
          </v-list-item> -->

          <!-- <v-list-item to="/purchase-orders" link>
            <v-list-item-title>Purchase Orders</v-list-item-title>
          </v-list-item> -->
        </v-list-group>
      </v-list-item-group>

      <!-- All the Nav Items under this block show when the hostname/doamin is safaricom.mediapal.net -->
      <v-list-item-group v-if="_isSafaricom" color="primary">
        <!-- Dashboard -->
        <v-list-item to="/" link>
          <v-list-item-icon>
            <v-icon>mdi-home-variant-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <!-- Discovery -->
        <v-list-item to="/tradedesk/discovery" link>
          <v-list-item-icon>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Discovery</v-list-item-title>
        </v-list-item>

        <!-- Campaigns -->
        <v-list-item to="/tradedesk/campaigns" link>
          <v-list-item-icon>
            <v-icon>mdi mdi-bullseye-arrow</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Campaigns</v-list-item-title>
        </v-list-item>

        <!-- Banners -->
        <v-list-item to="/tradedesk/creatives" link>
          <v-list-item-icon>
            <v-icon>mdi mdi-tablet-cellphone</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Banners</v-list-item-title>
        </v-list-item>

        <!-- Targeting -->
        <v-list-item to="/tradedesk/targeting" link>
          <v-list-item-icon>
            <v-icon>mdi mdi-target</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Targeting</v-list-item-title>
        </v-list-item>

        <!-- Reporting -->
        <v-list-item to="/tradedesk/reporting" link>
          <v-list-item-icon>
            <v-icon>mdi mdi-chart-bar</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Reporting</v-list-item-title>
        </v-list-item>

        <!-- Users -->
        <v-list-item to="/users-management/users" link>
          <v-list-item-icon>
            <v-icon>mdi mdi-account-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Users</v-list-item-title>
        </v-list-item>

        <!-- Billing -->
        <!-- <v-list-item to="/invoicing" link>
          <v-list-item-icon>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Billing</v-list-item-title>
        </v-list-item> -->

        <!-- Settings -->
        <v-list-item to="/settings" link>
          <v-list-item-icon>
            <v-icon>mdi mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
      </v-list-item-group>

      <v-list-item-group v-if="_isKWSAgency" color="primary">
        <!-- Dashboard -->
        <v-list-item to="/" link>
          <v-list-item-icon>
            <v-icon>mdi-home-variant-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>


        <!-- Campaigns -->
        <v-list-item to="/tradedesk/campaigns" link>
          <v-list-item-icon>
            <v-icon>mdi mdi-bullseye-arrow</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Campaigns</v-list-item-title>
        </v-list-item>

        <!-- Banners -->
        <v-list-item to="/tradedesk/creatives" link>
          <v-list-item-icon>
            <v-icon>mdi mdi-tablet-cellphone</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Banners</v-list-item-title>
        </v-list-item>


        <!-- Reporting -->
        <v-list-item to="/tradedesk/reporting" link>
          <v-list-item-icon>
            <v-icon>mdi mdi-chart-bar</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Reporting</v-list-item-title>
        </v-list-item>

        <!-- Users -->
        <v-list-item to="/users-management/users" link>
          <v-list-item-icon>
            <v-icon>mdi mdi-account-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Team</v-list-item-title>
        </v-list-item>

        <!-- Settings -->
        <v-list-item to="/settings" link>
          <v-list-item-icon>
            <v-icon>mdi mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  data: () => ({
    audiences: [
      ["Targeting", "/tradedesk/targeting"],
      ["User List", "/tradedesk/user-lists"],
      ["Rules", "/audiences/rules"],
    ],
    facebook: [
      {
        title: "Ad Accounts",
        url: "/facebook/ad-accounts",
      },
      {
        title: "Campaigns",
        url: "/facebook/campaigns",
      },
      {
        title: "Ad Sets",
        url: "/facebook/ad-sets",
      },
      {
        title: "Ads",
        url: "/facebook/ads",
      },
    ],
    opera: [
      {
        title: "Discovery",
        url: "/opera/discovery",
      },
      {
        title: "Campaigns",
        url: "/opera/campaigns",
      },
      {
        title: "Creatives",
        url: "/opera/creatives",
      },
      {
        title: "Reporting",
        url: "/opera/reporting",
      },
      {
        title: "Audiences",
        url: "/opera/audiences",
      },
      {
        title: "Leads",
        url: "/opera/leadgen",
      },
    ],
    phoenix: [
      {
        title: "Discovery",
        url: "/phoenix/discovery",
      },
      {
        title: "Campaigns",
        url: "/phoenix/campaigns",
      },
      {
        title: "Creatives",
        url: "/phoenix/creatives",
      },
      {
        title: "Reporting",
        url: "/phoenix/reporting",
      },
      {
        title: "Audiences",
        url: "/phoenix/audiences",
      },
      {
        title: "Leads",
        url: "/phoenix/leadgen",
      },
    ],
    gaming: [
      {
        title: "Discovery",
        url: "/gaming/discovery",
      },
      {
        title: "Campaigns",
        url: "/gaming/campaigns",
      },
      {
        title: "Creatives",
        url: "/gaming/creatives",
      },
      {
        title: "Reporting",
        url: "/gaming/reporting",
      },
      {
        title: "Audiences",
        url: "/gaming/audiences",
      },
      {
        title: "Leads",
        url: "/gaming/leadgen",
      },
    ],
    google: [
      {
        title: "Campaigns",
        url: "/google/campaigns",
      },
      {
        title: "Creatives",
        url: "/google/creatives",
      },
      {
        title: "Reporting",
        url: "/google/reporting",
      },
      {
        title: "Audiences",
        url: "/google/audiences",
      },
      {
        title: "Leads",
        url: "/google/leadgen",
      },
    ],
    transsion: [
      {
        title: "Discovery",
        url: "/transsion/discovery",
      },
      {
        title: "Campaigns",
        url: "/transsion/campaigns",
      },
      {
        title: "Creatives",
        url: "/transsion/creatives",
      },
      {
        title: "Reporting",
        url: "/transsion/reporting",
      },
      {
        title: "Audiences",
        url: "/transsion/audiences",
      },
      {
        title: "Leads",
        url: "/transsion/leadgen",
      },
    ],
    truecaller: [
      {
        title: "Discovery",
        url: "/truecaller/discovery",
      },
      {
        title: "Campaigns",
        url: "/truecaller/campaigns",
      },
      {
        title: "Creatives",
        url: "/truecaller/creatives",
      },
      {
        title: "Reporting",
        url: "/truecaller/reporting",
      },
      {
        title: "Audiences",
        url: "/truecaller/audiences",
      },
      {
        title: "Leads",
        url: "/truecaller/leadgen",
      },
    ],
    connected_tv: [
      {
        title: "Discovery",
        url: "/ctv/discovery",
      },
      {
        title: "Campaigns",
        url: "/ctv/campaigns",
      },
      {
        title: "Creatives",
        url: "/ctv/creatives",
      },
      {
        title: "Reporting",
        url: "/ctv/reporting",
      },
      {
        title: "Audiences",
        url: "/ctv/audiences",
      },
    ],
    users: [
      {
        title: "All Users",
        url: "/users-management/users",
      },
      {
        title: "Advertisers",
        url: "/users-management/clients",
      },
      {
        title: "Agencies",
        url: "/users-management/agencies/",
      },
      {
        title: "Permissions",
        url: "/users-management/permissions",
      },
      {
        title: "Roles",
        url: "/users-management/roles",
      },
    ],

    isHome: false,
  }),

  computed: {
    path: function () {
      return this.$route.name
    },
    _isBelva() {
      if (location.hostname == "desk.belvadigital.com") {
        return true
      } else return false
    },
    superAdmin() {
      return this.$store.getters.superAdmin
    },
    _isSafaricom() {
      return location.hostname == "safaricom.mediapal.net" ? true : false
    },
    _isKWSAgency() {
      return this.$store.getters.authenticatedUser.agency_id == 110
        ? true
        : false
    },
    __general() {
      //is not safaricom and is no belva and is not kws agency
      return !this._isSafaricom && !this._isKWSAgency
    },
  },
}
</script>

<style scoped>
.v-list-item__title,
.v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-weight: bold;
}

.beta {
  font-size: 8px !important;
}
</style>
