<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      color="#1976d2"
    ></loading>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  computed: {
    isLoading() {
      // return this.$store.state.isLoading;
      return false;
    },
  },
};
</script>
